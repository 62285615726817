import Station from "../route/StationType";
import LineCategoryConditionType from "./LineCategoryConditionType";
import LineConditionType from "./LineConditionType";

export default class StationConditionType {
  private readonly _id: string;
  private readonly _station: Station;
  private readonly _line_condition: LineConditionType;

  private _is_checked: boolean;

  constructor(station: Station, lineCondition: LineConditionType) {
    this._station = station;
    this._id = station.id;
    this._line_condition = lineCondition;
    this._is_checked = false;
  }

  get data(): Station {
    return this._station;
  }

  get id(): string {
    return this._id;
  }

  get is_checked(): boolean {
    return this._is_checked;
  }

  set is_checked(b: boolean) {
    this._is_checked = b;
  }

  get line_condition(): LineConditionType {
    return this._line_condition;
  }

  get line_category_condition(): LineCategoryConditionType {
    return this._line_condition.line_category_condition;
  }

  /******************************
   * チェック状態の変更系メソッド *
   ******************************/
  // 上位のエリアを含めて、チェック状態を変更する（外部から呼ぶメソッド）
  setChecked = (b: boolean): void => {
    this.is_checked = b;
    this.line_condition.refresh();
    this.line_category_condition.refresh();
  };

  /*****************
   * 表示系メソッド *
   *****************/
  // チェックされた内容をテキストで返す
  getText(): string {
    if (this.is_checked) {
      return this.data.display_name + "駅";
    } else {
      return "";
    }
  }
}

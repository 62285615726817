import LineCategory from "../route/LineCategoryType";
import LineConditionType from "./LineConditionType";
import StationConditionType from "./StationConditionType";

export default class LineCategoryConditionType {
  private readonly _id: string;
  private readonly _line_category: LineCategory;
  private readonly _line_conditions: LineConditionType[];

  private _is_checked: boolean;

  constructor(line_category: LineCategory) {
    this._line_category = line_category;
    this._id = line_category.id;
    this._line_conditions = line_category.lines.map((l) => new LineConditionType(l, this));
    this._is_checked = false;
  }

  get id(): string {
    return this._id;
  }

  get data(): LineCategory {
    return this._line_category;
  }

  get is_checked(): boolean {
    return this._is_checked;
  }

  set is_checked(b: boolean) {
    this._is_checked = b;
  }

  get line_conditions(): LineConditionType[] {
    return this._line_conditions;
  }

  get station_conditions(): StationConditionType[] {
    return this._line_conditions.flatMap((l) => l.station_conditions);
  }

  findLine = (id: string): LineConditionType | undefined => {
    return this.line_conditions.find((l) => l.id == id);
  };

  /******************************
   * チェック状態の確認系メソッド *
   ******************************/
  // チェックされた沿線の取得
  getCheckedLines(): LineConditionType[] {
    return this.line_conditions.filter((l) => l.is_checked);
  }

  // 1沿線でもチェックされているか否か
  hasCheckedLine(): boolean {
    return this.line_conditions.some((l) => l.is_checked);
  }

  // すべての沿線がチェックされているか
  everyLineIsChecked(): boolean {
    return this.line_conditions.every((l) => l.is_checked);
  }

  /******************************
   * チェック状態の変更系メソッド *
   ******************************/
  // 配下の沿線を含めて、チェック状態を変更する（外部から呼ぶメソッド）
  setChecked = (b: boolean): void => {
    this.is_checked = b;
    this.line_conditions.forEach((sc) => (sc.is_checked = b));
    this.station_conditions.forEach((sc) => (sc.is_checked = b));
  };

  // 配下のエリア状態を確認し、チェック状態を変更する（配下のエリアから呼ばれるメソッド）
  refresh = (): void => {
    this.is_checked = this.everyLineIsChecked();
  };
}

import Todofuken from "../region/TodofukenType";
import ShikugunConditionType from "./ShikugunConditionType";
import ShikugunGroupConditionType from "./ShikugunGroupConditionType";

export default class TodofukenConditionType {
  private readonly _id: string;
  private readonly _todofuken: Todofuken;
  private readonly _shikugun_group_conditions: ShikugunGroupConditionType[];

  private _is_checked: boolean;

  constructor(todofuken: Todofuken) {
    this._todofuken = todofuken;
    this._id = todofuken.id;
    this._shikugun_group_conditions = todofuken.shikugun_groups.map(
      (sg) => new ShikugunGroupConditionType(sg, this),
    );
    this._is_checked = false;
  }

  get id(): string {
    return this._id;
  }

  get data(): Todofuken {
    return this._todofuken;
  }

  get is_checked(): boolean {
    return this._is_checked;
  }

  set is_checked(b: boolean) {
    this._is_checked = b;
  }

  get shikugun_group_conditions(): ShikugunGroupConditionType[] {
    return this._shikugun_group_conditions;
  }

  get shikugun_conditions(): ShikugunConditionType[] {
    return this._shikugun_group_conditions.flatMap((sg) => sg.shikugun_conditions);
  }

  findShikugun = (id: string): ShikugunConditionType | undefined => {
    return this.shikugun_conditions.find((s) => s.id == id);
  };

  /******************************
   * チェック状態の確認系メソッド *
   ******************************/
  // チェックされた市区郡の取得
  getCheckedShikuguns(): ShikugunConditionType[] {
    return this.shikugun_conditions.filter((s) => s.is_checked);
  }

  // 1市区郡でもチェックされているか否か
  hasCheckedShikugun(): boolean {
    return this.shikugun_conditions.some((s) => s.is_checked);
  }

  // すべての市区郡がチェックされているか
  everyShikugunsIsChecked(): boolean {
    return this.shikugun_conditions.every((s) => s.is_checked);
  }

  /******************************
   * チェック状態の変更系メソッド *
   ******************************/
  // 配下のエリアを含めて、チェック状態を変更する（外部から呼ぶメソッド）
  setChecked = (b: boolean): void => {
    this.is_checked = b;
    this.shikugun_group_conditions.forEach((sg) => (sg.is_checked = b));
    this.shikugun_conditions.forEach((sg) => (sg.is_checked = b));
  };

  // 配下のエリア状態を確認し、チェック状態を変更する（配下のエリアから呼ばれるメソッド）
  refresh = (): void => {
    this.is_checked = this.everyShikugunsIsChecked();
  };

  /*****************
   * 表示系メソッド *
   *****************/
  // チェックされた内容をテキストで返す
  getText(): string {
    if (this.is_checked) {
      // 都道府県がチェックされていれば、都道府県名を返す
      return this.data.display_name;
    } else {
      return this.shikugun_group_conditions
        .map((sg) => sg.getText())
        .filter((v) => !!v)
        .join(", ");
    }
  }
}

import ShikugunGroup from "../region/ShikugunGroupType";
import ShikugunConditionType from "./ShikugunConditionType";
import TodofukenConditionType from "./TodofukenConditionType";

export default class ShikugunGroupConditionType {
  private readonly _id: string | null;
  private readonly _shikugun_group: ShikugunGroup;
  private readonly _shikugun_conditions: ShikugunConditionType[];
  private readonly _todofuken_condition: TodofukenConditionType;

  private _is_checked: boolean;

  constructor(shikugunGroup: ShikugunGroup, todofukenCondition: TodofukenConditionType) {
    this._id = shikugunGroup.id;
    this._shikugun_group = shikugunGroup;
    this._todofuken_condition = todofukenCondition;
    this._shikugun_conditions = shikugunGroup?.shikuguns?.map(
      (s) => new ShikugunConditionType(s, this),
    );
    this._is_checked = false;
  }

  get data(): ShikugunGroup {
    return this._shikugun_group;
  }

  get id(): string | null {
    return this._id;
  }

  get is_checked(): boolean {
    return this._is_checked;
  }

  set is_checked(b: boolean) {
    this._is_checked = b;
  }

  everyShikugunCheckedExceptGroup(todofuken_id: string) {
    const shikugunsExceptGroup = this._shikugun_conditions.filter(
      (shikugun) =>
        shikugun.data.todofuken_id === todofuken_id && shikugun.shikugun_group_id === null,
    );
    return shikugunsExceptGroup.every((shikugun) => shikugun.is_checked);
  }

  get todofuken_condition(): TodofukenConditionType {
    return this._todofuken_condition;
  }

  get shikugun_conditions(): ShikugunConditionType[] {
    return this._shikugun_conditions;
  }

  /******************************
   * チェック状態の確認系メソッド *
   ******************************/
  // チェックされた市区郡の取得
  getCheckedShikuguns(): ShikugunConditionType[] {
    return this.shikugun_conditions.filter((s) => s.is_checked);
  }

  // 1市区郡でもチェックされているか否か
  hasCheckedShikugun(): boolean {
    return this.shikugun_conditions.some((s) => s.is_checked);
  }

  // すべての市区郡がチェックされているか
  everyShikugunsIsChecked(): boolean {
    return this.shikugun_conditions.every((s) => s.is_checked);
  }

  /******************************
   * チェック状態の変更系メソッド *
   ******************************/
  // 上位および配下のエリアを含めて、チェック状態を変更する（外部から呼ぶメソッド）
  setChecked = (b: boolean): void => {
    this.is_checked = b;
    this.shikugun_conditions.forEach((sc) => (sc.is_checked = b));
    this.todofuken_condition.refresh();
  };

  // 配下のエリア状態を確認し、チェック状態を変更する（配下のエリアから呼ばれるメソッド）
  refresh = (): void => {
    this.is_checked = this.everyShikugunsIsChecked();
  };

  /*****************
   * 表示系メソッド *
   *****************/
  // チェックされた内容をテキストで返す
  getText(): string {
    if (this.is_checked) {
      // 市区郡カテゴリがチェックされていれば、市区郡カテゴリ名を返す
      return this.data.display_name;
    } else {
      return this.shikugun_conditions
        .map((s) => s.getText())
        .filter((v) => !!v)
        .join(", ");
    }
  }
}

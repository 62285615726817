import Line from "../route/LineType";
import LineCategoryConditionType from "./LineCategoryConditionType";
import StationConditionType from "./StationConditionType";

export default class LineConditionType {
  private readonly _id: string;
  private readonly _line: Line;
  private readonly _station_conditions: StationConditionType[];
  private readonly _line_category_condition: LineCategoryConditionType;

  private _is_checked: boolean;

  constructor(line: Line, line_category_condition: LineCategoryConditionType) {
    this._line = line;
    this._id = line.id;
    this._station_conditions = line.stations.map((s) => new StationConditionType(s, this));
    this._line_category_condition = line_category_condition;
    this._is_checked = false;
  }

  get id(): string {
    return this._id;
  }

  get data(): Line {
    return this._line;
  }

  get is_checked(): boolean {
    return this._is_checked;
  }

  set is_checked(b: boolean) {
    this._is_checked = b;
  }

  get station_conditions(): StationConditionType[] {
    return this._station_conditions;
  }

  get line_category_condition(): LineCategoryConditionType {
    return this._line_category_condition;
  }

  findStation = (id: string): StationConditionType | undefined => {
    return this.station_conditions.find((s) => s.id == id);
  };

  findStationByStationId = (id: string): StationConditionType | undefined => {
    return this.station_conditions.find((station) => station.data.station_id === id);
  };

  /******************************
   * チェック状態の確認系メソッド *
   ******************************/
  // チェックされた駅の取得
  getCheckedStations(): StationConditionType[] {
    return this.station_conditions.filter((s) => s.is_checked);
  }

  // 1駅でもチェックされているか否か
  hasCheckedStation(): boolean {
    return this.station_conditions.some((s) => s.is_checked);
  }

  // すべての駅がチェックされているか
  everyStationsIsChecked(): boolean {
    return this.station_conditions.every((s) => s.is_checked);
  }

  /******************************
   * チェック状態の変更系メソッド *
   ******************************/
  // 配下の駅を含めて、チェック状態を変更する（外部から呼ぶメソッド）
  setChecked = (b: boolean): void => {
    this.is_checked = b;
    this.station_conditions.forEach((s) => (s.is_checked = b));
    this.line_category_condition.refresh();
  };

  // 配下の駅状態を確認し、チェック状態を変更する（配下のエリアから呼ばれるメソッド）
  refresh = (): void => {
    this.is_checked = this.everyStationsIsChecked();
  };

  /*****************
   * 表示系メソッド *
   *****************/
  // チェックされた内容をテキストで返す
  getText(): string {
    if (this.is_checked) {
      // 沿線がチェックされていれば、沿線名を返す
      return this.data.display_name;
    } else {
      return this.station_conditions
        .map((sc) => sc.getText())
        .filter((v) => !!v)
        .join(", ");
    }
  }
}

import Shikugun from "../region/ShikugunType";
import ShikugunGroupConditionType from "./ShikugunGroupConditionType";
import TodofukenConditionType from "./TodofukenConditionType";

export default class ShikugunConditionType {
  private readonly _id: string;
  private readonly _shikugun_group_id: string | null;
  private readonly _shikugun: Shikugun;
  private readonly _shikugun_group_condition: ShikugunGroupConditionType;
  private _is_checked: boolean;

  constructor(shikugun: Shikugun, shikugunGroupCondition: ShikugunGroupConditionType) {
    this._shikugun = shikugun;
    this._id = shikugun.id;
    this._shikugun_group_id = shikugun.shikugun_group_id;
    this._shikugun_group_condition = shikugunGroupCondition;
    this._is_checked = false;
  }

  get data(): Shikugun {
    return this._shikugun;
  }

  get id(): string {
    return this._id;
  }

  get shikugun_group_id(): string | null {
    return this._shikugun_group_id;
  }

  get is_checked(): boolean {
    return this._is_checked;
  }

  set is_checked(b: boolean) {
    this._is_checked = b;
  }

  get todofuken_condition(): TodofukenConditionType | undefined {
    return this.shikugun_group_condition?.todofuken_condition;
  }

  get shikugun_group_condition(): ShikugunGroupConditionType {
    return this._shikugun_group_condition;
  }

  /******************************
   * チェック状態の変更系メソッド *
   ******************************/
  // 上位のエリアを含めて、チェック状態を変更する（外部から呼ぶメソッド）
  setChecked = (b: boolean): void => {
    this.is_checked = b;
    this.shikugun_group_condition?.refresh();
    this.todofuken_condition?.refresh();
  };

  /*****************
   * 表示系メソッド *
   *****************/
  // チェックされた内容をテキストで返す
  getText(): string {
    if (this.is_checked) {
      return this.data.display_name;
    } else {
      return "";
    }
  }
}

import LineCategory from "../route/LineCategoryType";
import LineCategoryConditionType from "./LineCategoryConditionType";
import LineConditionType from "./LineConditionType";
import StationConditionType from "./StationConditionType";
import { QUERY_KEY } from "@/pages/list/Logic/Condition/QueryKey";

export default class RouteConditionType {
  private readonly _line_category_conditions: LineCategoryConditionType[];

  constructor(
    lineStatus: { [id: string]: boolean } | null = null,
    stationStatus: { [id: string]: boolean } | null = null,
  ) {
    this._line_category_conditions = LineCategory.all().map(
      (lineCategory) => new LineCategoryConditionType(lineCategory),
    );

    if (!!lineStatus) {
      for (const [id, b] of Object.entries(lineStatus)) {
        this.findLine(id)?.setChecked(b);
      }
    }
    if (!!stationStatus) {
      for (const [id, b] of Object.entries(stationStatus)) {
        this.findStation(id)?.setChecked(b);
      }
    }
  }

  get line_category_conditions(): LineCategoryConditionType[] {
    return this._line_category_conditions;
  }

  get line_conditions(): LineConditionType[] {
    return this._line_category_conditions.flatMap((lc) => lc.line_conditions);
  }

  get station_conditions(): StationConditionType[] {
    return this._line_category_conditions.flatMap((lc) => lc.station_conditions);
  }

  /****************
   * 検索系メソッド *
   ****************/
  findLineCategory = (id: string): LineCategoryConditionType | undefined => {
    return this.line_category_conditions.find((line_category) => line_category.id === id);
  };

  findLine = (id: string): LineConditionType | undefined => {
    return this.line_conditions.find((line) => line.id === id);
  };

  findStation = (id: string): StationConditionType | undefined => {
    return this.station_conditions.find((station) => station.id === id);
  };

  findLinesByStationId = (id: string): LineConditionType[] | undefined => {
    const matchingLines = this.line_conditions.filter((line) => line.findStationByStationId(id));
    return matchingLines.length > 0 ? matchingLines : undefined;
  };

  filterLineCategories = (ids: string[]): LineConditionType[] | undefined => {
    return this.line_conditions.filter((lc) => ids.includes(lc.id));
  };

  /************************************
   * 検索系メソッド(なければエラーを返す版) *
   ************************************/
  findLineCategoryStrictly = (id: string): LineCategoryConditionType => {
    const target = this.findLineCategory(id);
    if (!target) {
      throw new TypeError(`lineCategoryId ${id} is invalid.`);
    }
    return target;
  };

  findLineStrictly = (id: string): LineConditionType => {
    const target = this.findLine(id);
    if (!target) {
      throw new TypeError(`lineId ${id} is invalid.`);
    }
    return target;
  };

  findStationStrictly = (id: string): StationConditionType => {
    const target = this.findStation(id);
    if (!target) {
      throw new TypeError(`stationId ${id} is invalid.`);
    }
    return target;
  };

  /******************************
   * チェック状態の確認系メソッド *
   ******************************/
  // チェックされている沿線を取得
  getCheckedLines = (): LineConditionType[] => {
    return this.line_conditions.filter((l) => l.is_checked);
  };

  // チェックされている駅の沿線を取得
  getCheckedLinesFromStations(): LineConditionType[] {
    const checkedStations = this.getCheckedStations();
    const checkedLineMap = new Map<string, LineConditionType>();

    checkedStations.forEach((station) => {
      const line = station.line_condition;
      checkedLineMap.set(line.id, line);
    });

    return Array.from(checkedLineMap.values());
  }

  // チェックされた駅の取得
  // 引数をtrueにすると、沿線が選択されている駅を除外する
  getCheckedStations(excludesLineSelected = false): StationConditionType[] {
    return this.station_conditions.filter(
      (s) => (!excludesLineSelected || !s.line_condition.is_checked) && s.is_checked,
    );
  }

  // チェックされている沿線があるか否か
  hasCheckedLine = (): boolean => {
    return this.line_conditions.some((l) => l.is_checked);
  };

  // 1駅でもチェックされているか否か
  hasCheckedStation(): boolean {
    return this.station_conditions.some((s) => s.is_checked);
  }

  // すべての駅がチェックされているか
  everyStationsIsChecked(): boolean {
    return this.station_conditions.every((s) => s.is_checked);
  }

  /******************************
   * チェック状態の変更系メソッド *
   ******************************/
  // 配下のエリアを含めて、チェック状態を変更する（外部から呼ぶメソッド）
  setChecked = (b: boolean): void => {
    this.line_conditions.forEach((t) => t.setChecked(b));
  };

  // 全てを更新した上で、新しいインスタンスを返す
  static setAll = (routeCondition: RouteConditionType, b: boolean): RouteConditionType => {
    const clone = Object.assign(new RouteConditionType(), routeCondition);
    clone.setChecked(b);
    return clone;
  };

  // 指定された沿線カテゴリを更新した上で、新しいインスタンスを返す
  static setLineCategory = (
    routeCondition: RouteConditionType,
    lineCategoryId: string,
    b: boolean,
  ): RouteConditionType => {
    const clone = Object.assign(new RouteConditionType(), routeCondition);
    clone.findLineCategory(lineCategoryId)?.setChecked(b);
    return clone;
  };

  // 指定された沿線を更新した上で、新しいインスタンスを返す
  static setLine = (
    routeCondition: RouteConditionType,
    lineId: string,
    b: boolean,
  ): RouteConditionType => {
    const clone = Object.assign(new RouteConditionType(), routeCondition);
    clone.findLine(lineId)?.setChecked(b);
    return clone;
  };

  // 指定された駅を更新した上で、新しいインスタンスを返す
  static setStation = (
    routeCondition: RouteConditionType,
    stationId: string,
    b: boolean,
  ): RouteConditionType => {
    const clone = Object.assign(new RouteConditionType(), routeCondition);
    clone.findStation(stationId)?.setChecked(b);
    return clone;
  };

  /*****************
   * 表示系メソッド *
   *****************/
  // チェックされた内容を全てまとめたテキストを返す
  getText(): string {
    return this.line_conditions
      .map((t) => t.getText())
      .filter((v) => !!v)
      .join(", ");
  }

  // 物件一覧のタイトルに用いる、短いテキストを返す
  // チェックされている沿線/駅のうち最初のものを表示
  getTitleText = (): string => {
    return (
      this.getCheckedLines()?.[0]?.getText() || this.getCheckedStations()?.[0]?.getText() || ""
    );
  };

  // 沿線パラメータの生成
  getLineParameter = (): { [key: string]: boolean } => {
    return this.getCheckedLines().reduce<Record<string, boolean>>((obj, line) => {
      obj[line.id] = line.is_checked;
      return obj;
    }, {});
  };

  // 駅パラメータの生成
  // 引数をtrueにすると、沿線が選択されている駅を除外する
  getStationParameter = (excludesLineSelected = false): { [key: string]: boolean } => {
    return this.getCheckedStations(excludesLineSelected).reduce<Record<string, boolean>>(
      (obj, station) => {
        obj[station.id] = station.is_checked;
        return obj;
      },
      {},
    );
  };

  // クエリの生成
  getQuery = (): { [key: string]: string } => {
    const buildQuery = (key: string, values: string[]) => {
      return {
        [key]: ["d", ...values].join("-"),
      };
    };

    const getLineQuery = (): { [key: string]: string } => {
      const ids = this.getCheckedLines().map((line) => line.id);
      return ids.length > 0 ? buildQuery(QUERY_KEY.LINE, ids) : {};
    };

    const getStationQuery = (): { [key: string]: string } => {
      const ids = this.getCheckedStations(true).map((station) => station.id);
      return ids.length > 0 ? buildQuery(QUERY_KEY.STATION, ids) : {};
    };

    return {
      ...getLineQuery(),
      ...getStationQuery(),
    };
  };
}
